import {useAuth} from "@/libs/auth";
import {mustEnterPayment} from "@/services/stripe-store";
import router from "next/router";
import {useEffect} from "react";

const GeneralLayout = ({ children }: any) => {
  const { user, token } = useAuth();

  useEffect(() => {
    if (user) {
      mustEnterPayment(token, user.stripeCredits, user.stripeCustomerId)
        .then((result) => {
          if (result === true) {
            router.push({
              pathname: "personal-settings/billing",
              query: {enterCard: true},
            })
          }
        })
        .catch(error => console.error(error))
    }
  }, [token, user]);

  return (
    <>
      <main>{children}</main>
    </>
  );
};

export default GeneralLayout;
