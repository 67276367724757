import {storage} from './firebase';

const uploadFile = (path: string, file: any) => {
  const ref = storage.ref(path);
  return ref.put(file);
}

const uploadFileString = (path: string, file: any) => {
  const ref = storage.ref(path);
  return ref.putString(file, 'data_url');
}

const getDownloadUrl = (path: string) => {
  const ref = storage.ref(path);
  return ref.getDownloadURL();
}

export {uploadFile, uploadFileString};
